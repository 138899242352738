import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mx-auto",attrs:{"elevation":"2","min-width":"244"},on:{"click":function($event){return _vm.$router.push({ path: `/courses/${_vm.course.id}` })}}},[_c('div',{staticClass:"video-container"},[_c(VImg,{attrs:{"height":"100%","width":"100%","src":_vm.course.thumbnail},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c(VRow,{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c(VProgressCircular,{attrs:{"indeterminate":"","size":"180","color":"primary"}})],1)]},proxy:true}])}),_c(VSheet,{staticClass:"d-flex align-center white--text video-info pa-4 rounded-tl-lg",attrs:{"color":"primary"}},[_c('a',[_c(VIcon,{staticClass:"video-play-icon",attrs:{"left":"","small":"","color":"white"}},[_vm._v("fa-play")]),_c('span',{staticClass:"text-decoration-underline white--text"},[_vm._v("Klik hier om verder te leren")])],1)])],1),_c(VCardTitle,{staticClass:"font--heading"},[_vm._v(_vm._s(_vm.course.name))]),_c(VCardSubtitle,[_vm._v(_vm._s(_vm.course.description))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }