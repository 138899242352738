
import Vue from 'vue';
import { setInteractionMode } from 'vee-validate';
import userApi from '@/api/user';

setInteractionMode('eager');

export default Vue.extend({
  name: 'PasswordReset',
  data() {
    return {
      password: '',
      passwordconfirm: '',
      resetToken: this.$route.query.token,

      isResettingPassword: false,
      passwordResetSent: false,
      showPassword: false,
      showPasswordConfirm: false,
    };
  },
  methods: {
    async onSubmit() {
      this.isResettingPassword = true;

      userApi
        .resetPassword(this.resetToken as string, this.password)
        .then((res) => {
          this.passwordResetSent = res.status === 200;
        })
        .catch((error) => {
          this.$toast.error('Er is een fout opgetreden, probeer het later opnieuw');
        })
        .finally(() => {
          this.isResettingPassword = false;
        });
    },
  },
});
