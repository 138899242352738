
import Vue, { PropType } from 'vue';
import { Course } from '@/api/interfaces';

export default Vue.extend({
  name: 'CouseInformation',
  props: {
    course: Object as PropType<Course>,
  },
});
