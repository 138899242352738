import { AxiosResponse } from 'axios';
import axios from '@/util/axios';
import store from '@/store';
import { Course, Feedback, Paginated } from './interfaces';

export default {
  async getCourses(page?: number): Promise<Paginated<Course[]>> {
    return ((await axios.get<Paginated<Course[]>>(`/course${page ? `?page=${page}` : ''}`)).data);
  },
  async getSubscribedCourses(page?: number): Promise<Paginated<Course[]>> {
    const userId = (store.state as any).user.user.id as number;
    return ((await axios.get<Paginated<Course[]>>(`/user/${userId}/course${page ? `?page=${page}` : ''}`)).data);
  },
  async getFeedback(id: number): Promise<Feedback> {
    return (await axios.get<Feedback>(`/score/${id}`)).data;
  },
  async getCourse(id: number): Promise<Course> {
    return (await axios.get<Course>(`/course/${id}`)).data;
  },
  async subscribeCourse(id: number): Promise<AxiosResponse> {
    const userId = (store.state as any).user.user.id as number;
    return axios.put(`/user/${userId}/course/${id}`);
  },
  async unsubscribeCourse(id: number): Promise<AxiosResponse> {
    const userId = (store.state as any).user.user.id as number;
    return axios.delete(`/user/${userId}/course/${id}`);
  },
  async eligibleForCertificate(id: number): Promise<AxiosResponse> {
    const userId = (store.state as any).user.user.id as number;
    return axios.get(`/user/${userId}/course/${id}/eligible`);
  },
  async getCertificate(id: number): Promise<AxiosResponse> {
    const userId = (store.state as any).user.user.id as number;
    return axios.get(`/user/${userId}/course/${id}/certificate`);
  },
};
