/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export default [
  {
    path: '/courses',
    name: 'Courses',
    component: () => import(/* webpackChunkName: "courses-index" */ '@/views/CourseOverview.vue'),
  },
  {
    path: '/courses/:courseId',
    name: 'Course',
    props: true,
    component: () => import(/* webpackChunkName: "courses-index-single" */ '@/views/subviews/Course.vue'),
  },
  {
    path: '/courses/:courseId/lessons/:lessonId',
    name: 'Lesson',
    props: true,
    component: () => import(/* webpackChunkName: "lesson-index-single" */ '@/views/subviews/Lesson.vue'),
  },
  {
    path: '/courses/:courseId/exams/:examId',
    name: 'Exam',
    props: true,
    component: () => import(/* webpackChunkName: "exam-index-single" */ '@/views/subviews/Exam/Exam.vue'),
  },
];
