import { VBadge } from 'vuetify/lib/components/VBadge';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VNavigationDrawer,{attrs:{"app":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c(VDivider),_c(VListItem,{attrs:{"two-line":""}},[_c(VListItemAvatar,[_c('img',{attrs:{"src":"https://picsum.photos/100/100"}})]),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Actief")]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.fullName))])],1)],1)]},proxy:true}]),model:{value:(_vm.drawerVisible),callback:function ($$v) {_vm.drawerVisible=$$v},expression:"drawerVisible"}},[_c('img',{staticClass:"logo ml-8 mt-4 mb-4",attrs:{"src":_vm.logo,"alt":"Logo"}}),_c(VList,{attrs:{"dense":"","nav":""}},[_c(VListItemGroup,{attrs:{"active-class":"primary--text eva-nav-item--active"}},_vm._l((_vm.items),function(item){return _c(VListItem,{key:item.title,staticClass:"ml-4 eva-nav-item",attrs:{"to":item.link,"disabled":item.disabled}},[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"font-weight-bold font-size-normal"},[(item.notifications)?_c(VBadge,{staticStyle:{"margin":"0"},attrs:{"color":"error","content":"6","inline":""}},[_vm._v(_vm._s(item.title))]):_vm._e(),(item.title == 'Logout')?_c('span',{on:{"click":_vm.logout}},[_vm._v(_vm._s(item.title))]):_c('span',[_vm._v(_vm._s(item.title))])],1)],1)],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }