
import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import { isProtectedRoute, isPublicRoute, replaceWithLoginPage } from '@/router';

export default Vue.extend({
  name: 'App',
  computed: {
    ...mapState('app', ['isLoading']),
    ...mapState('user', ['user']),
    ...mapGetters('user', ['isAuthenticated']),
    determineLayout(): string {
      if (this.isAuthenticated) {
        return 'VDefaultLayout';
      }

      return 'VUnauthLayout';
    },
  },
  watch: {
    $route(to) {
      requestAnimationFrame(async () => {
        if (!this.isLoading && !this.user && isProtectedRoute(to.name) && !isPublicRoute(to.name)) {
          await replaceWithLoginPage(to.path);
        }
      });
    },
  },
});
