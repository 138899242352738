/* eslint-disable import/prefer-default-export */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';

for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
  });
}

// === Extends ===

extend('password-confirm', {
  params: ['target'],
  validate(value, { target }: any) {
    return value === target;
  },
  message: 'Wachtwoorden komen niet overeen',
});
