
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  name: 'Navbar',
  data: () => ({
    drawerVisible: true,
  }),
  methods: {
    ...mapActions('user', ['logout']),
    openDrawer() {
      this.$emit('openDrawer');
    },
  },
  computed: {
    ...mapGetters('user', ['fullName']),
  },
});
