import axios from 'axios';

const storedUser = localStorage.getItem('eva_user');
let axiosHeaders = {};

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
axios.defaults.timeout = 10000; // 10 seconds
axiosHeaders = { Accept: 'application/json' };

// Add Bearer token header if we've got a session in localstorage
if (storedUser) {
  const user = JSON.parse(storedUser);
  axiosHeaders = { ...axiosHeaders, Authorization: `Bearer ${user.access_token}` };
}

// Allows to receive a session cookie from backend and pass the session cookie back to the backend.
// See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Access-Control-Allow-Credentials
axios.defaults.withCredentials = false;
axios.defaults.headers.common = axiosHeaders;

// Axios interceptor for 401
axios.interceptors.response.use((response) => response, (error) => {
  if (error.response.status === 401) {
    localStorage.removeItem('eva_user');
    window.location.replace('/login');
  }
  return Promise.reject(error);
});

export default axios;
