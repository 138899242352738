/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { AxiosError, AxiosResponse } from 'axios';
import Home from '@/views/Home.vue';
import Profile from '@/views/Profile.vue';
import store from '@/store';
import { UserState } from '@/store/modules/user';
import userApi from '@/api/user';

// Routes
import AuthRoutes from '@/router/auth';
import CourseRoutes from '@/router/dashboard/courses';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
  },
  ...AuthRoutes,
  ...CourseRoutes,
  // TODO - Implement a 404 Not Found
  // {
  //   path: '*',
  //   name: 'NotFoundError',
  //   component
  // }
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

const authRouteNames = AuthRoutes.map((route) => route.name);

/**
 * @returns **true** if a route name is protected and that the route should
 * only be used when the user is authenticated.
 * @returns **false** if route is public, like a login or password-forget route
 * and should only be accessed when not authenticated
 */
export function isProtectedRoute(routeName: string | null | undefined) {
  if (!routeName) {
    return true;
  }

  return ![...authRouteNames, 'Error'].includes(routeName || '');
}

/**
 * Returns true if routeName passed as parameter is public
 */
export function isPublicRoute(routeName: string | null | undefined) {
  return ['Login', 'Register', 'PasswordForget', 'PasswordReset', 'OAuth', 'Invite'].includes(routeName || '');
}

/**
 * Replaces current page with login page and adds the redirect query parameter from the path the user tried to navigate to
 */
export async function replaceWithLoginPage(fromPath: string) {
  return router.replace({
    name: 'Login',
    query: {
      redirect: fromPath !== '/' ? fromPath : undefined,
    },
  });
}

/**
 * User session check before every other route at first load
 */
router.beforeEach(async (to, _from, next) => {
  const { user } = (store.state as any).user as UserState;

  if (!user || !user.access_token) {
    // Allows public route to load
    if (isPublicRoute(to.name)) {
      return next();
    }

    if (isProtectedRoute(to.name)) {
      await replaceWithLoginPage(to.path);
    }
    return false;
  }
  // Allows public route to load
  if (isPublicRoute(to.name)) {
    next();
  }

  // Redirects to main page, when a non-protected route is accessed
  if (!isProtectedRoute(to.name)) {
    router.replace({
      name: 'Home',
    });
  }

  store.commit('app/setIsLoading', false);
  next();

  return true;
});

/**
 * Page not found router catch-all
 */
router.beforeEach((to, _from, next) => {
  const r = router.resolve({ name: to.name || '' });
  if (r.resolved.matched.length === 0 && r.route.name !== 'NotFoundError') {
    router.push({ name: 'NotFoundError' });
  } else next();
});

router.afterEach(() => {
  store.commit('app/setIsLoading', false);
});

export default router;
