import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VApp,[_c(VFadeTransition,[(_vm.isLoadingOverlay)?_c(VOverlay,{attrs:{"color":"white","z-index":"99","opacity":"0.5"}},[_c(VProgressLinear,{style:({ width: '225px' }),attrs:{"indeterminate":"","rounded":"","height":"6"}})],1):_vm._e()],1),(!_vm.isInExam)?_c('sidebar',{ref:"navdrawer"}):_vm._e(),(!_vm.isInExam)?_c(VAppBar,{attrs:{"color":"white","elevation":"0","hide-on-scroll":"","app":""}},[_c('navbar',{on:{"openDrawer":_vm.openDrawer}})],1):_vm._e(),_c(VMain,[_c(VContainer,{attrs:{"fluid":""}},[_vm._t("default")],2)],1),_c(VFooter,{attrs:{"app":""}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }