
import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import { AxiosError } from 'axios';
import store from '@/store';
import userApi, { Profile, UserSession } from '@/api/user';

export default Vue.extend({
  name: 'Profile',
  data() {
    return {
      picker: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
      userInfo: {} as UserSession,
      isUpdatingProfile: false,
      showUpdateSuccess: false,
    };
  },
  mounted() {
    this.setIsLoadingOverlay(true);
    userApi.getCurrentUser((store.state as any).user.user.access_token).then((res: UserSession) => {
      this.userInfo = res;
      this.setIsLoadingOverlay(false);
    });
  },
  computed: {
    ...mapState('app', ['isLoadingOverlay']),
  },
  methods: {
    ...mapMutations('app', ['setIsLoadingOverlay']),
    ...mapMutations('user', ['setProfile']),
    async onSubmit() {
      this.isUpdatingProfile = true;
      userApi
        .updateUserProfile(this.userInfo.profile.id, this.userInfo.profile)
        .then((res) => {
          if (res.status === 200) {
            const newProfile = res.data as Profile;
            this.setProfile(newProfile);

            (this.$refs.validationObserver as any).reset();
            this.isUpdatingProfile = false;
            this.showUpdateSuccess = true;
          }
        })
        .catch((reason: AxiosError) => {
          this.$toast.error('Er is een fout opgetreden, probeer het later opnieuw');
          this.isUpdatingProfile = false;
        });
    },
  },
});
