
import Vue from 'vue';
import { mapState } from 'vuex';
import Sidebar from '@/components/Sidebar.vue';
import Navbar from '@/components/Navbar.vue';

export default Vue.extend({
  name: 'DefaultLayout',
  components: {
    Sidebar,
    Navbar,
  },
  methods: {
    openDrawer() {
      (this.$refs.navdrawer as any).toggleDrawer();
    },
  },
  computed: {
    ...mapState('app', ['isLoadingOverlay', 'isInExam']),
  },
});
