import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VRow,{attrs:{"align":"center"}},[_c(VCol,{staticClass:"d-none d-lg-flex"},[_c('h2',{staticClass:"dark--text"},[_c('span',{staticClass:"light--text"},[_vm._v("Hallo,")]),_vm._v(" "+_vm._s(_vm.fullName)+"!")])]),_c(VCol,{staticClass:"d-md-flex d-lg-none",attrs:{"cols":"auto"}},[_c(VBtn,{attrs:{"icon":""},on:{"click":_vm.openDrawer}},[_c(VIcon,[_vm._v("fa-bars")])],1)],1),_c(VCol,[_c('div',{staticClass:"d-flex"},[_c(VTextField,{staticClass:"rounded-0 rounded-tl rounded-bl",attrs:{"label":"Zoeken","placeholder":"Bijv. cursus programmeren","outlined":"","dense":"","clearable":"","hide-details":"auto"}}),_c(VBtn,{staticClass:"rounded-0 rounded-tr rounded-br",staticStyle:{"height":"40px"},attrs:{"color":"primary"}},[_c(VIcon,{attrs:{"small":""}},[_vm._v("fa-search")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }