import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationObserver',{ref:"validationObserver",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.emailSent)?_c(VAlert,{attrs:{"outlined":"","type":"success","text":""}},[_vm._v(" Als er een account is geregistreerd met dat email adres dan hebben we een mail gestuurd! ")]):_vm._e(),_c('h1',{staticClass:"primary--text"},[_vm._v("Wachtwoord vergeten?")]),_c('h3',{staticClass:"mt-2 mb-4 font--body font-weight-regular"},[_vm._v("Vul je email in en we sturen je een link om je wachtwoord opnieuw in te stellen")]),_c('ValidationProvider',{attrs:{"name":'Email',"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c(VTextField,{attrs:{"type":"email","label":"Email","error-messages":errors,"disabled":_vm.isForgettingPassword,"outlined":"","tabindex":"1"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c(VRow,{staticClass:"flex-column flex-lg-row",attrs:{"align":"center","dense":""}},[_c(VSpacer),_c(VCol,{staticClass:"d-lg-flex justify-lg-end",attrs:{"order":"0","order-lg":"1"}},[_c(VBtn,{staticClass:"mr-2",attrs:{"color":"primary","disabled":_vm.isForgettingPassword,"tabindex":"1","outlined":""},on:{"click":function($event){return _vm.$router.push({ name: 'Login' })}}},[_vm._v("Terug naar inloggen")]),_c(VBtn,{attrs:{"type":"submit","color":"primary","loading":_vm.isForgettingPassword,"disabled":_vm.emailSent,"tabindex":"1"}},[_vm._v("Verstuur mail")])],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }